import React from 'react';
import {Checkbox, Col, Form, Row} from "antd";
import {Grid} from "pti-zoo";
import {useUserSelector} from "../../store";

export const OverviewTab: React.FC=()=>{
  const user=useUserSelector(state=>state.users);
  console.log(user.profile);
  return (
    <Col {...Grid.TwentyFourTwentyFourths}>
      <Row gutter={[16,16]}>
        <Col {...Grid.TwentyFourTwentyFourths}>
          <Form labelCol={{span: 4}} wrapperCol={{span: 20}} initialValues={{ remember: true }}>
            <Form.Item label={"Name"} name={"name"}>
              <span>{`${user.profile?.first_name} ${user.profile?.last_name}`}</span>
            </Form.Item>
            <Form.Item label={"Username"} name={"username"}>
              <span>{user.profile?.username}</span>
            </Form.Item>
            <Form.Item label={"Email"} name={"email"}>
              <span>{user.profile?.email}</span>
            </Form.Item>
            <Form.Item label={"Active"} name={"active"}>
              <Checkbox checked={user.profile?.is_active} disabled={true} />
            </Form.Item>
            <Form.Item label={"Staff"} name={"staff"}>
              <Checkbox checked={user.profile?.is_staff} disabled={true} />
            </Form.Item>
            <Form.Item label={"Superuser"} name={"superuser"}>
              <Checkbox checked={user.profile?.is_superuser} disabled={true} />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </Col>
  );
}
