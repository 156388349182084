import React from 'react';
import {ConnectedRouter as Router} from 'connected-react-router';
import {Switch, Route} from "react-router-dom";
import {Provider} from 'react-redux';
import {CookieLoader} from "./components/CookieLoader";
import CookieConsent from "react-cookie-consent";

import {store, history, loggedIn} from './store';
import {PrivateRoute} from "pti-zoo";
import {ActivatePage, HomePage, LoginPage, LogoutPage, ProfilePage, SignUpPage} from "./pages";
import {DESIMAL_USE_COOKIES_COOKIE} from "./consts";

const App: React.FC = () => {
  return (
      <Provider store={store}>
        <CookieLoader>
          <Router history={history}>
            <Switch>
              <Route path={'/'} exact={true} component={HomePage} />
              <Route path={'/login'} component={LoginPage} />
              <Route path={'/activate'} component={ActivatePage} />
              <PrivateRoute path={"/profile"} component={ProfilePage} loggedIn={loggedIn} />
              <PrivateRoute path={"/signup"} component={SignUpPage} loggedIn={loggedIn} />
              <PrivateRoute path={"/logout"} component={LogoutPage} loggedIn={loggedIn} />
            </Switch>
            <CookieConsent
                enableDeclineButton={true}
                onAccept={()=>{}}
                onDecline={()=>{}}
                cookieName={DESIMAL_USE_COOKIES_COOKIE}>
              We use cookies...
            </CookieConsent>
          </Router>
        </CookieLoader>
      </Provider>
  )
}

export default App;
